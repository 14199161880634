.texteVente{
    color: white;
    font-size: 20px;
    margin-top: 160px;
    text-align: right;
    margin-right: 103px;
    font-style: italic;
    @media screen and (max-width: 820px) {
      margin-top: 70px;
      text-align: center;
      margin-right: 0px;    }
  }
.wall-toile {
    max-width: 1600px;
    margin: auto;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 820px) {
      justify-content: center;
    }
    @media screen and (max-width: 630px) {
      margin-top: 38px;
    }
    @media screen and (max-width: 500px) {
      margin-bottom: 80px;
    }
    @media screen and (max-width: 320px) {
      margin-top: 20px;
    }
  
    .card-container {
      width: 350px;
      margin-bottom: 18px;
      margin-right: 9px;
      margin-left: 9px;
      box-shadow: 0 4px 10px 0 rgba($color: black, $alpha: 0.5);
      transition: all ease-in-out 0.2s;
      &:hover {
        transform: scale(1.15);
        transition: all ease-in-out 0.2s;
      }
      @media screen and (max-width: 1120px) {
        width: 300px;
      }
      @media screen and (max-width: 971px) {
        width: 250px;
      }
      @media screen and (max-width: 600px) {
        // margin-right: 18px;
        // margin-left: 18px;
        width: 260px;
      }
      @media screen and (max-width: 500px) {
        width: 280px;
      }

      img {
        width: 100%;
        padding: 8px;
        transition: all ease-in-out 0.2s;
        // &:hover {
        //   transform: scale(1.15);
        //   transition: all ease-in-out 0.2s;
        // }
      }
  
      .footer-card {
        text-align: center;
        padding: 0 8px 8px 8px;
        position: relative;
  
        .info-toile {
          h3 {
            white-space: nowrap;
            font-family: $font-main;
            font-size: 1em;
            font-weight: 100;
          }
          span {
            font-size: 12px;
            padding-top: 8px;
            display: flex;
            justify-content: center;
            font-style: italic;
            .toileDate{
                padding-left: 3px;
            }
          }
          .toileDisponible{
            color: rgb(64, 96, 202);
            margin-top: 8px;
          }
          .toileVendu{
            color: rgb(252, 48, 48);
            margin-top: 8px;
          }
        }
      }
    }
  }