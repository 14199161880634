// *** FONT ***//

// $font-main: "Open Sans", sans-serif;
$font-main: 'Montserrat', sans-serif;
// $font-secondary: "Comforter Brush", cursive;
$font-secondary: "Corinthia", cursive;
$font-title: "Oswald", sans-serif;

// *** COLOR ***//

$color-background: black;
$color-main: #ffffff;
$color-secondary: #03edc0;

//***  GENERAL ***//

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  outline: none;
  border: none;
  text-decoration: none;
  color: $color-main;
  user-select: none;
}
body {
  position: relative;
  background-color: $color-background;
  color: $color-main;
  font-family: $font-main;
  height: 100%;
  // overflow-y: hidden;
}