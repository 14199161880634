 
 .toile{

    h1{
        margin-top: 110px;
        text-align: center;
        @media screen and (max-width: 500px) {
            margin-top: 0px;
          }
    }

    .wall-print {
        max-width: 1600px;
        margin: auto;
        margin-top: 90px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 820px) {
          justify-content: center;
        }
        @media screen and (max-width: 630px) {
          margin-top: 38px;
        }
        @media screen and (max-width: 500px) {
          margin-bottom: 80px;
        }
        @media screen and (max-width: 320px) {
          margin-top: 20px;
        }
      
        .card-container {
          width:460px;
          margin-bottom: 18px;
          margin-right: 9px;
          margin-left: 9px;
          box-shadow: 0 4px 10px 0 rgba($color: black, $alpha: 0.5);
          transition: all ease-in-out 0.2s;
          &:hover {
            transform: scale(1.15);
            transition: all ease-in-out 0.2s;
          }
          @media screen and (max-width: 1450px) {
            width: 350px;
          }
          @media screen and (max-width: 1120px) {
            width: 300px;
          }
          @media screen and (max-width: 971px) {
            width: 250px;
          }
          @media screen and (max-width: 820px) {
            width: 460px;
          }
          @media screen and (max-width: 600px) {
            // margin-right: 18px;
            // margin-left: 18px;
            width: 260px;
          }
          @media screen and (max-width: 500px) {
            width: 280px;
          }
    
          img {
            width: 100%;
            padding: 8px;
            transition: all ease-in-out 0.2s;
            // &:hover {
            //   transform: scale(1.15);
            //   transition: all ease-in-out 0.2s;
            // }
          }
      
          .footer-card {
            text-align: center;
            padding: 0 8px 8px 8px;
            position: relative;
      
            .info-toile {
              h3 {
                white-space: nowrap;
                font-family: $font-main;
                font-size: 1em;
                font-weight: 100;
              }
              span {
                font-size: 12px;
                padding-top: 8px;
                display: flex;
                justify-content: center;
                font-style: italic;
                .toileDate{
                    padding-left: 3px;
                }
              }
            }
          }
        }
    }


    .collab-text{
        padding: 20px;
        margin: auto;
        margin-bottom: 30px;
        max-width: 1600px;
        margin-top: 30px;
    
    
        .text-en{
            position: relative;
            margin-top: 50px;
            padding-top: 50px;
    
            ::before{
                content: "";
                position: absolute;
                left: 0px;
                right: 0px;
                top: 0px;
                height: 2px;
                background-color: white;
    
    
            }
        }
    
        .text-right{
            margin-top: 50px;
            text-align: right;
            font-style: italic;
            
        }
    
        a:hover{
            text-decoration: underline;
        }
    }


    .slider-container{
        max-width: 585px;
        margin: auto;
        position: relative;
        padding: 20px;
        @media screen and (max-width: 590px) {
            max-width: 450px;
          }
        @media screen and (max-width: 500px) {
            margin-bottom: 70px;
        }
        
        .img-slider-collab{
            max-height: 100%;
            max-width: 100%;
        }

        button{
            :hover{
                cursor: pointer;
            }
            position: absolute;
            top: 50%;
            width: 35px;
            height: 35px;
            background-color: transparent;
            @media screen and (max-width: 726px) {
                display: none;
              }
        }
        .cta-next{
            span::after{
                font-family: FontAwesome;
                content: "\f054";
                display: inline-block;
                font-weight: 900;
                color: white;
            }
            
            right: -30px;
            
        }
        .cta-preview{
            span::after{
                font-family: FontAwesome;
                content: "\f053";
                display: inline-block;
                font-weight: 900;
                color: white;
            }

            left: -30px;
        }


    }
        
 }
 
 
 


  

.swipe {
    overflow: hidden;
    visibility: hidden;
    position: relative;
  }
  .swipe-wrap {
    overflow: hidden;
    position: relative;
  }
  .swipe-wrap > div {
    float: left;
    width: 100%;
    position: relative;
}